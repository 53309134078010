(function (videojs) {
	var vjsButton = videojs.getComponent('Button');
	videojs.registerPlugin('subtitle', function (options) {
		var _videoJsPlayer = this;
		var isOpen = false;
		_videoJsPlayer.ready(function () {
			var _tMenuButton = _videoJsPlayer.controlBar.addChild(new vjsButton(_videoJsPlayer, options), {}),
				$tMenuButton = $(_tMenuButton.el()),
				label = document.createElement('div');
			label.classList.add('vjs-subtitle-control');
			label.innerText = 'CC';
			$tMenuButton.append(label);
			_videoJsPlayer.controlBar.el().insertBefore(_tMenuButton.el(), _videoJsPlayer.controlBar.getChild('downloadButton').el());
			fetch(_tMenuButton.options_.file, {
				headers: {
					"Authorization": "Bearer " + localStorage.getItem("token")
				}
			}).then(response => response.blob())
				.then(function (blob) {
					blob.text().then(function (content) {
						var srtRegex = /(.*\n)?(\d\d:\d\d:\d\d),(\d\d\d --> \d\d:\d\d:\d\d),(\d\d\d)/g;
						var vttText = 'WEBVTT\n\n' + content.replace(srtRegex, '$1$2.$3.$4');
						var vttBlob = new Blob([vttText], { type: 'text/vtt' });
						var blobURL = URL.createObjectURL(vttBlob);
						_videoJsPlayer.ready(function () {
							_videoJsPlayer.addRemoteTextTrack({
								src: blobURL,
								mode: 'showing',
								srclang: 'en',
								label: 'english',
								kind: 'subtitles'
							}, true);
						});
					})

					var menu = document.createElement('div');
					menu.classList.add('subtitle-menu-container');

					var menuTitle = document.createElement('div');
					menuTitle.classList.add('subtitle-label');
					menuTitle.classList.add('_600');

					menuTitle.innerText = 'Subtitle';
					menu.append(menuTitle);

					var menuItem = document.createElement('div');
					menuItem.classList.add('subtitle-menu-item');

					var checkboxLabel = document.createElement('label');
					checkboxLabel.classList.add('mtm-checkbox');
					checkboxLabel.classList.add('vjs-subtitle-toggle');
					checkboxLabel.classList.add('mtm-checkbox-green');

					var inputCheck = document.createElement('input');
					inputCheck.classList.add('form-check-input');
					inputCheck.setAttribute('type', 'checkbox');
					inputCheck.setAttribute('checked', true);

					var checkboxSpan = document.createElement('span');
					checkboxSpan.classList.add('checkmark');

					checkboxLabel.addEventListener('click', function (e) {
						e.preventDefault();
						e.stopImmediatePropagation();
						e.stopPropagation();
						var isChecked = inputCheck.getAttribute('checked');
						if (isChecked) {
							inputCheck.removeAttribute('checked');
							_videoJsPlayer.textTrackDisplay.hide();
						} else {
							inputCheck.setAttribute('checked', true);
							_videoJsPlayer.textTrackDisplay.show();
						}
					});

					checkboxLabel.append(inputCheck);
					checkboxLabel.append(checkboxSpan);

					var fileName = document.createElement('span');
					fileName.classList.add('file-name-label');
					fileName.classList.add('text-truncate');
					fileName.innerText = _tMenuButton.options_.caption;
					fileName.addEventListener('click', function (e) {
						e.preventDefault();
						e.stopImmediatePropagation();
						e.stopPropagation();
						var isChecked = inputCheck.getAttribute('checked');
						if (isChecked) {
							inputCheck.removeAttribute('checked');
							_videoJsPlayer.textTrackDisplay.hide();
						} else {
							inputCheck.setAttribute('checked', true);
							_videoJsPlayer.textTrackDisplay.show();
						}
					});

					var fileDownload = document.createElement('span');
					fileDownload.classList.add('file-download');
					fileDownload.innerText = 'Download';

					fileDownload.addEventListener('click', function (e) {
						e.preventDefault();
						if (_tMenuButton.options_.file && _tMenuButton.options_.downloadCallback && typeof _tMenuButton.options_.downloadCallback === 'function') {
							_tMenuButton.options_.downloadCallback();
						}
					});

					menuItem.append(checkboxLabel);
					menuItem.append(fileName);
					menuItem.append(fileDownload);

					menu.append(menuItem);
					$tMenuButton.append(menu);
					_tMenuButton.controlText(_tMenuButton.options_.caption);
					_tMenuButton.addClass('vjs-subtitle-icon');
					_tMenuButton.el().addEventListener('click', function (e) {
						e.preventDefault();
						isOpen = !isOpen;
						_videoJsPlayer.controlBar.el().classList[isOpen ? 'add' : 'remove']('subtitle-menu-open');
					});

					function closeSubtitle(e) {
						var isSubtitle = e.target.classList.contains('vjs-subtitle-control');
						if (!isSubtitle) {
							isOpen = false;
							if (_videoJsPlayer.controlBar.el()) {
								_videoJsPlayer.controlBar.el().classList[isOpen ? 'add' : 'remove']('subtitle-menu-open');
							}
						}
					}

					var body = document.getElementsByTagName('body');
					body[0].addEventListener('click', closeSubtitle);
					_videoJsPlayer.on('dispose', function () {
						body[0].removeEventListener('click', closeSubtitle);
					});
				});
		});
	});
})(videojs);
