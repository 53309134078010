(function (videojs) {
	var vjsButton = videojs.getComponent('Button');
	videojs.registerPlugin('threeDotMenu', function (options) {
		var _videoJsPlayer = this;
		var isOpen = false;
		_videoJsPlayer.ready(function () {
			var _tMenuButton = _videoJsPlayer.controlBar.addChild(new vjsButton(_videoJsPlayer, options), {});
			_tMenuButton.controlText('Toggle Menus');
			_tMenuButton.addClass('vjs-ellipsis-icon');
			_tMenuButton.el().addEventListener('click', function (e) {
				e.preventDefault();
				isOpen = !isOpen;
				_videoJsPlayer.controlBar.el().classList[isOpen ? 'add' : 'remove']('three-dot-menu-hovered');
			});
		});
	});
})(videojs);
