(function (f) {
	if (typeof exports === "object" && typeof module !== "undefined") {
		module.exports = f()
	} else if (typeof define === "function" && define.amd) {
		define([], f)
	} else {
		var g;
		if (typeof window !== "undefined") {
			g = window
		} else if (typeof global !== "undefined") {
			g = global
		} else if (typeof self !== "undefined") {
			g = self
		} else {
			g = this
		}
		g.videojsVjsdownload = f()
	}
})(function () {
	var define, module, exports;
	return (function e(t, n, r) {
		function s(o, u) {
			if (!n[o]) {
				if (!t[o]) {
					var a = typeof require == "function" && require;
					if (!u && a) return a(o, !0);
					if (i) return i(o, !0);
					var f = new Error("Cannot find module '" + o + "'");
					throw f.code = "MODULE_NOT_FOUND", f
				}
				var l = n[o] = {
					exports: {}
				};
				t[o][0].call(l.exports, function (e) {
					var n = t[o][1][e];
					return s(n ? n : e)
				}, l, l.exports, e, t, n, r)
			}
			return n[o].exports
		}
		var i = typeof require == "function" && require;
		for (var o = 0; o < r.length; o++) s(r[o]);
		return s
	})({
		1: [function (require, module, exports) {
			(function (global) {
				'use strict';

				Object.defineProperty(exports, '__esModule', {
					value: true
				});

				var _createClass = (function () {
					function defineProperties(target, props) {
						for (var i = 0; i < props.length; i++) {
							var descriptor = props[i];
							descriptor.enumerable = descriptor.enumerable || false;
							descriptor.configurable = true;
							if ('value' in descriptor) descriptor.writable = true;
							Object.defineProperty(target, descriptor.key, descriptor);
						}
					}
					return function (Constructor, protoProps, staticProps) {
						if (protoProps) defineProperties(Constructor.prototype, protoProps);
						if (staticProps) defineProperties(Constructor, staticProps);
						return Constructor;
					};
				})();

				var _get = function get(_x, _x2, _x3) {
					var _again = true;
					_function: while (_again) {
						var object = _x,
							property = _x2,
							receiver = _x3;
						_again = false;
						if (object === null) object = Function.prototype;
						var desc = Object.getOwnPropertyDescriptor(object, property);
						if (desc === undefined) {
							var parent = Object.getPrototypeOf(object);
							if (parent === null) {
								return undefined;
							} else {
								_x = parent;
								_x2 = property;
								_x3 = receiver;
								_again = true;
								desc = parent = undefined;
								continue _function;
							}
						} else if ('value' in desc) {
							return desc.value;
						} else {
							var getter = desc.get;
							if (getter === undefined) {
								return undefined;
							}
							return getter.call(receiver);
						}
					}
				};

				function _interopRequireDefault(obj) {
					return obj && obj.__esModule ? obj : {
						'default': obj
					};
				}

				function _classCallCheck(instance, Constructor) {
					if (!(instance instanceof Constructor)) {
						throw new TypeError('Cannot call a class as a function');
					}
				}

				function _inherits(subClass, superClass) {
					if (typeof superClass !== 'function' && superClass !== null) {
						throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass);
					}
					subClass.prototype = Object.create(superClass && superClass.prototype, {
						constructor: {
							value: subClass,
							enumerable: false,
							writable: true,
							configurable: true
						}
					});
					if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
				}

				var _videoJs = (typeof window !== "undefined" ? window['videojs'] : typeof global !== "undefined" ? global['videojs'] : null);

				var _videoJs2 = _interopRequireDefault(_videoJs);

				var defaults = {
					beforeElement: 'fullscreenToggle',
					textControl: 'Download video',
					name: 'downloadButton',
					downloadURL: null
				};

				var vjsButton = _videoJs2['default'].getComponent('Button');

				var DownloadButton = (function (_vjsButton) {
					_inherits(DownloadButton, _vjsButton);

					function DownloadButton() {
						_classCallCheck(this, DownloadButton);

						_get(Object.getPrototypeOf(DownloadButton.prototype), 'constructor', this).apply(this, arguments);

						var menu = document.createElement('ul');
						menu.classList.add('vjs-download-menu');

						var menuLabel = document.createElement('li');
						menuLabel.innerText = "Original";
						menuLabel.classList.add('menu-label');
						menu.append(menuLabel);

						var originalDownload = document.createElement('li');
						originalDownload.innerText = this.options_.originalMedia.codec.toUpperCase() + ' ' + this.options_.originalMedia.resolution;
						originalDownload.classList.add('original-file');
						originalDownload.classList.add('downloadable-file');

						(function (_this, _el) {
							_el.addEventListener('click', function (e) {
								e.preventDefault();
								if (_this.options_.downloadCallback && typeof _this.options_.downloadCallback === 'function') {
									_this.options_.downloadCallback(_this.options_.controlId);
								} else {
									download(_this.options_.downloadURL || p.currentSrc(), void 0, {
										control: 'button.vjs-vjsdownload'
									});
								}
								/* _this.el_.classList.remove('toggled'); */
							})
						})(this, originalDownload);
						menu.append(originalDownload);

						if (this.options_.mediaQuality.length) {
							var lowerQualityLabel = document.createElement('li');
							lowerQualityLabel.innerText = "Lower quality";
							lowerQualityLabel.classList.add('lower-quality-file');
							menu.append(lowerQualityLabel);
						}

						for (var i = 0; i < this.options_.mediaQuality.length; i++) {
							var child = document.createElement('li');
							child.innerText = this.options_.mediaQuality[i].codec + ' ' + this.options_.mediaQuality[i].resolution;
							child.classList.add('downloadable-file');
							(function (_this, _el, _i) {
								_el.addEventListener('click', function (e) {
									e.preventDefault();
									if (_this.options_.downloadCallback && typeof _this.options_.downloadCallback === 'function') {
										_this.options_.downloadCallback(_this.options_.controlId, _this.options_.mediaQuality[_i].quality);
									} else {
										download(_this.options_.downloadURL || p.currentSrc(), void 0, {
											control: 'button.vjs-vjsdownload'
										});
									}
									/* _this.el_.classList.remove('toggled'); */
								})
							})(this, child, i);
							menu.append(child);
						}
						this.el_.append(menu);

					}

					_createClass(DownloadButton, [{
						key: 'buildCSSClass',
						value: function buildCSSClass() {
							return 'vjs-vjsdownload ' + _get(Object.getPrototypeOf(DownloadButton.prototype), 'buildCSSClass', this).call(this);
						}
					}, {
						key: 'handleClick',
						value: function handleClick() {
							if (this.el_.classList.contains('downloading')) {
								return;
							}
							/* this.el_.classList.contains('toggled')
								? this.el_.classList.remove('toggled')
								: this.el_.classList.add('toggled'); */
						}
					}]);
					return DownloadButton;
				})(vjsButton);

				var onPlayerReady = function onPlayerReady(player, options) {
					var DButton = player.controlBar.addChild(new DownloadButton(player, options), {});

					if (options.controlId) {
						DButton.el_.setAttribute('id', options.controlId);
					}

					if (options.isDownloading) {
						DButton.el_.classList.add('downloading');
					}

					DButton.controlText(options.textControl);

					player.controlBar.el().insertBefore(DButton.el(), player.controlBar.getChild(options.beforeElement).el());

					player.addClass('vjs-vjsdownload');

					$('.vjs-vjsdownload.vjs-control.vjs-button').on("click", () => {
						$('.vjs-vjsdownload.vjs-control.vjs-button').toggleClass("vjs-menu-open");
					})
				};

				var vjsdownload = function vjsdownload(options) {
					var _this = this;

					this.ready(function () {
						onPlayerReady(_this, _videoJs2['default'].mergeOptions(defaults, options));
					});
				};

				_videoJs2['default'].registerPlugin('vjsdownload', vjsdownload);

				exports['default'] = vjsdownload;
				module.exports = exports['default'];
			}).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
		}, {}]
	}, {}, [1])(1)
});
