var Logger = {};
(function (Logger) {
	var LOG_PREFIX = '::VAC::';
	// Are we in debug mode?
	function debug() {
		return !!window.VAC_DEBUG;
	}
	// Build arguments for console fn, adding prefix
	function buildArgs(args) {
		return [LOG_PREFIX].concat(args);
	}
	Logger.log = function (args) {
		if (!debug()) return;
		console.log.apply(null, buildArgs(args));
	}
	Logger.info = function (args) {
		if (!debug()) return;
		console.info.apply(null, buildArgs(args));
	}
	Logger.error = function (args) {
		if (!debug()) return;
		console.error.apply(null, buildArgs(args));
	}
})(Logger);